import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import IntroPage from "../components/TextIntroPages/IntroPage"

const BusinessDevelopment = () => {
    return (
        <div id="page-wrap" >
            <Header />
            <IntroPage />
            <Footer />
        </div>
    )
}

export default BusinessDevelopment;