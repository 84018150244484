import { useContext } from "react";
import Header from "../../components/Header/Header"
import classes from "./subPages.module.css"
import AuthContext from "../../store/auth-context";
import Footer from "../../components/Footer/Footer";

const Impressum = () => {
    const ctx = useContext(AuthContext);

    const data = ctx.text.Impressum;

    return (
        <div id="page-wrap">
            <Header />
            <main className={classes.wrap}>
                <div className={classes.container}>
                    <h1>{data.h1}</h1>
                    <p>{data.p1}</p>
                    <p>{data.p2}</p>
                    <p>{data.p3}</p>
                    <p>{data.p4}</p>
                    <p>{data.p5}</p>
                    <p>{data.p6}</p>
                    <p>{data.p61}</p>
                    <p>{data.p62}</p>
                    <p>{data.p63}</p>
                    <p>{data.p7}</p>
                    <p>{data.p8}</p>
                    <p>{data.p9}</p>
                    <p>{data.p10}</p>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Impressum;