import { Fragment } from "react"
import "./Arrow.scss";

const Arrow = (props) => {
    return (
        <Fragment>
            <div className={`arrow ${props.className}`}></div>
        </Fragment>
    )
}

export default Arrow;