import { useContext } from "react";
import Header from "../../components/Header/Header"
import AuthContext from "../../store/auth-context";
import Footer from "../../components/Footer/Footer";
import classes from "./subPages.module.css"


const DataProtection = () => {
    const ctx = useContext(AuthContext);

    const data = ctx.text.DataProtection
    return (
        <div id="page-wrap">
            <Header />
            <main className={classes.wrap}>
                <div>
                    <h1>{data.h1}</h1>
                    <div>
                        <p>{data.p1}</p>
                    </div>
                    <div >
                        <h2>{data.n1}</h2>
                        <p>{data.np1}</p>
                        <p>{data.np12}</p>
                        <p>{data.np13}</p>
                        <p>{data.np14}</p>
                        <p>{data.np15}</p>
                        <p>{data.np16}</p>
                        <p>{data.np17}</p>
                        <p className={classes.extraSpace}>{data.np18}</p>
                    </div>
                    <div>
                        <h2>{data.n2}</h2>
                        <p>{data.np2}</p>
                        <p>{data.np3}</p>
                        <p>{data.np4}</p>
                        <p>{data.np41}</p>
                        <p>{data.np42}</p>
                        <p>{data.np43}</p>
                        <p>{data.np44}</p>
                        <p>{data.np45}</p>
                        <p>{data.np46}</p>
                        <p>{data.np47}</p>
                        <p>{data.np48}</p>
                    </div>
                    <div>
                        <h2>{data.n5}</h2>
                        <p>{data.np51}</p>
                        <p>{data.np52}</p>
                        <p>{data.np53}</p>
                        <p>{data.np54}</p>
                        <p>{data.np55}</p>
                        <p>{data.np56}</p>
                        <p>{data.np57}</p>
                        <p>{data.np58}</p>
                        <p>{data.np59}</p>
                        <p>{data.np510}</p>
                    </div>
                    <div>
                        <h2>{data.n6}</h2>
                        <p>{data.np61}</p>
                        <p>{data.np62}</p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default DataProtection;