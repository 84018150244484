
import { StrictMode, useContext, useEffect, useRef } from 'react';
import classes from "./CardGrid.module.css"
import AuthContext from '../../../store/auth-context';
import Arrow from '../../UI/Arrow/Arrow';
import vid from "../../../video/masknew0001-0500.webm";
import { useWindowScrollPositions as userPos } from "../../../hooks/useWindowScrollPositions"

const CardGrid = () => {
    const ctx = useContext(AuthContext)
    const data = ctx.text.Home.AllergoEye;
    const { scrollY } = userPos()

    let fading = scrollY > 300 ? classes.animation : classes.text;

    const videoRef = useRef();

    useEffect(() => {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));

        const videoPlay = async () => {
            await delay(3000);
            videoRef.current.play();
        }
        if (scrollY > 300) {
            videoPlay();
        }
    }, [scrollY])



    return (
        <StrictMode>
            <section id="AE" className={classes.wrapper}>
                <div className={classes.container}>
                    <div className={classes.vidContainer}>
                        {<video width="100%" ref={videoRef}  muted loop onContextMenu={(e) => e.preventDefault()}>
                            <source src={vid} type="video/webm" />
                        </video>}
                        {/* {<img src={vid} alt="mask" />} */}
                    </div>
                    <div className={`${fading} ${classes.textWrap}`}>
                        <h1>{data.h1}</h1>
                        <h2>{data.h2}</h2>
                        <p>{data.p}
                            <br /><br />{data.Br1} <br />{data.Br2} <br />{data.Br3} <br />{data.Br4}
                        </p>
                    </div>
                </div>
                <Arrow className={classes.arrowGrid} />
            </section>
        </StrictMode>
    )
}

export default CardGrid;