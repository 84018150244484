import React, { useCallback, useEffect, useState } from "react"

let logOutTimer;

const AuthContext = React.createContext({
    user: '',
    token: '',
    isLoggedIn: false,
    languageID: '',
    cookies: false,
    accepted: false,
    login: (token) => { },
    langHandler: (lan) => { },
    logout: () => { },
    cookiesHandler: () => { },
    acceptedHandler: () => { }
})

const calculateRemainingTime = (expirationTime) => {
    const currenTime = new Date().getTime();
    const adjExperiationTime = new Date(expirationTime).getTime();

    const remiaingDuration = adjExperiationTime - currenTime;

    return remiaingDuration;
}

const revitrieveToken = () => {
    const storedToken = localStorage.getItem('token')
    const storedTime = localStorage.getItem('expirationTime')
    const storedUser = localStorage.getItem('user');
    const storedAge = localStorage.getItem('age');
    const storedsurname = localStorage.getItem('surname')
    const storedEmail = localStorage.getItem('email')
    const remiaingTime = calculateRemainingTime(storedTime)

    if (remiaingTime <= 3600) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('surname')
        localStorage.removeItem('age')
        localStorage.removeItem('email')
        localStorage.removeItem('expirationTime')

        return (null)
    }

    return ({
        token: storedToken,
        time: remiaingTime,
        userData: {
            user: storedUser,
            surname: storedsurname,
            age: storedAge,
            email: storedEmail
        }
    })
}

export const AuthContextProvider = (props) => {
    const tokenData = revitrieveToken();

    let language = localStorage.getItem('language');
    let initCookies = localStorage.getItem('CookiesAuth')
    let initAccep = localStorage.getItem('Accepted')
    let initialToken;
    let userData;
    
    if (!language) {
        language = "EN";
    }
    if (tokenData) {
        initialToken = tokenData.token;
        userData = tokenData.userData;
    }
    if (!initCookies)
        initCookies = false;
    if (!initAccep)
        initAccep = false;
    const [token, setToken] = useState(initialToken);
    const [user, setUser] = useState(userData);
    const [languageText, setLanguage] = useState(Object.assign({}, require(`../i18n/${language.toLocaleLowerCase()}.json`)));
    const [cookies, setCookies] = useState(initCookies)
    const [accepted, setAccepted] = useState(initAccep)

    const userIsLoggedIn = !!token;

    const langHandler = (lang) => {
        console.log(lang)
        setLanguage(Object.assign({}, require(`../i18n/${lang.toLocaleLowerCase()}.json`)))
        if (accepted)
            localStorage.setItem('language', lang);
    }

    const logOutHandler = useCallback(() => {
        setToken(null)
        setUser(null)
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('surname');
        localStorage.removeItem('age');
        localStorage.removeItem('email');

        if (logOutTimer) {
            clearTimeout(logOutTimer)
        }
    }, [])

    const loginHandler = (token, data, expirationTime) => {
        setToken(token)
        setUser(data);
        if (cookies) {
            localStorage.setItem('user', data.name)
            localStorage.setItem('surname', data.surname)
            localStorage.setItem('email', data.email)
            localStorage.setItem('age', data.age)
            localStorage.setItem('token', token)
            localStorage.setItem('expirationTime', expirationTime)

            const remiaingTime = calculateRemainingTime(expirationTime);

            logOutTimer = setTimeout(logOutHandler, remiaingTime);
        }
    }

    const cookieHandler = () => {
        setCookies(true)
        localStorage.setItem('Accepted', true)
        localStorage.setItem('CookiesAuth', true)
    }


    const acceptedHandler = () => {
        setAccepted(true)
    }

    useEffect(() => {
        if (tokenData) {
            logOutTimer = setTimeout(logOutHandler, tokenData.time)
        }
    }, [logOutHandler, tokenData])


    const contextValue = {
        user: user,
        token: token,
        isLoggedIn: userIsLoggedIn,
        languageID: language,
        text: languageText,
        cookies: cookies,
        accepted: accepted,
        langHandler: langHandler,
        login: loginHandler,
        logout: logOutHandler,
        cookieHandler: cookieHandler,
        acceptedHandler: acceptedHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext