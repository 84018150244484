
import { useLocation } from "react-router-dom";
import Arrow from "../UI/Arrow/Arrow";
import Canva from "../UI/Canva/Canva";
import BSText from "./BSText";
import module from "./IntroPage.module.css";
import IntroText from "./IntroText";
import ScText from "./ScText";

const IntroPage = () => {
    const location = useLocation();

    let text;

    if (location.pathname === "/scientific-work") { text = <ScText /> }
    else if (location.pathname === "/BusinessDevelopment") { text = <BSText />; }
    else {
        text = <IntroText />
    }

    return (
        <section className={module.wrapper}>
            <div className={module.wrap}>
                <div className={module.text}>
                    {text}
                </div>
                <div className={module.canvasWrap}>
                    <Canva className={module.canvas} />
                </div>
            </div>
           {location.pathname !==  "/BusinessDevelopment" &&  <Arrow />}
        </section>
    )
}

export default IntroPage;