import { Fragment, useContext, useEffect,  useState } from "react";
import { slide as Menu } from "react-burger-menu";
import AuthContext from "../../store/auth-context";
import home from "../../Img/home.png"
import ae from "../../Img/AE.png"
import sci from "../../Img/sci.png"
import BsDev from "../../Img/business.png"
import contact from "../../Img/mail.png"
import module from "./Icon.module.css"

// import ae from "../../Img/icons/AllergoEye.svg"
import "./Menu.css"
import { NavLink } from "react-router-dom";

const MenuHamburger = () => {
    const ctx = useContext(AuthContext);

    const [screenSize, getDimension] = useState(window.innerWidth);

    const setDimension = () => {
        getDimension(window.innerWidth)
    }



    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])



    // const logOutHandler = () => {
    //     ctx.logout();
    // }
    const [isOpen, setIsOpen] = useState(true)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        if (screenSize <= 1100)
            setIsMobile(true)
    }, [screenSize])

    const data = ctx.text.Menu

    const ChangeHandler = () => {
        if (isOpen)
            setIsOpen(false)
        else
            setIsOpen(true)
    }

    const iconBar = isOpen === true ? module.btnB : ""

    return (
        <Fragment>
            {!isMobile && <Menu
                pageWrapId={"page-wrap"}
                burgerBarClassName={iconBar}
                isOpen={isOpen}
                onStateChange={ChangeHandler}
                noOverlay
                disableAutoFocus
            >
                <div className={module.container}>
                    <div>
                        <NavLink className="menu-item" to="/Home">
                            <img src={home} alt="Home" />
                            {data.home}</NavLink>
                    </div>
                    {ctx.user && <div>
                        <NavLink className="menu-item" to="/Profile">
                            <img src={home} alt="Profile" />
                            {data.profile}</NavLink>
                    </div>}
                    <div>
                        <NavLink className="menu-item" to="/AE">
                            <img src={ae} alt="Allergo Eye" />
                            {data.ae}</NavLink>
                    </div>
                    <div>
                        <NavLink className="menu-item" to="/scientific-work">
                            <img src={sci} alt="Scientific Work" />
                            {data.sc}</NavLink>
                    </div>
                    <div>
                        <NavLink className="menu-item" to="/BusinessDevelopment">
                            <img src={BsDev} alt="Business Development" />
                            {data.bs}</NavLink>
                    </div>
                    {/* <div>

                    {!ctx.isLoggedIn &&
                        <NavLink className="menu-item" to="/Login">
                            <img src={support} alt="Login" />
                            Login</NavLink>}
                    {ctx.isLoggedIn &&
                        <NavLink className="menu-item" to="/" onClick={logOutHandler}>
                            <img src={support} alt="Logout" />
                            Logout</NavLink>}
                </div> */}
                    <div>
                        <NavLink className="menu-item" to="/contact">
                            <img src={contact} alt="Contact" />
                            {data.contact}</NavLink>
                    </div>
                </div>
            </Menu>}
            {isMobile && <div className="headerM">
                <Menu
                    pageWrapId={"page-wrap"}
                >
                    <div className={module.container}>
                        <div>
                            <NavLink className="menu-item" to="/Home">
                                <img src={home} alt="Home" />
                                {data.home}</NavLink>
                        </div>
                        {ctx.user && <div>
                            <NavLink className="menu-item" to="/Profile">
                                <img src={home} alt="Profile" />
                                {data.profile}</NavLink>
                        </div>}
                        <div>
                            <NavLink className="menu-item" to="/AE">
                                <img src={ae} alt="Allergo Eye" />
                                {data.ae}</NavLink>
                        </div>
                        <div>
                            <NavLink className="menu-item" to="/scientific-work">
                                <img src={sci} alt="Scientific Work" />
                                {data.sc}</NavLink>
                        </div>
                        <div>
                            <NavLink className="menu-item" to="/BusinessDevelopment">
                                <img src={BsDev} alt="Business Development" />
                                {data.bs}</NavLink>
                        </div>
                        {/* <div>

                    {!ctx.isLoggedIn &&
                        <NavLink className="menu-item" to="/Login">
                            <img src={support} alt="Login" />
                            Login</NavLink>}
                    {ctx.isLoggedIn &&
                        <NavLink className="menu-item" to="/" onClick={logOutHandler}>
                            <img src={support} alt="Logout" />
                            Logout</NavLink>}
                </div> */}
                        <div>
                            <NavLink className="menu-item" to="/contact">
                                <img src={contact} alt="Contact" />
                                {data.contact}</NavLink>
                        </div>
                    </div>
                </Menu>
            </div>
            }
        </Fragment>
    )
}

export default MenuHamburger;