
import { useContext } from 'react';
import classes from "./AeMain.module.css"
import vid from "../../video/Video_AE.mp4"
import AuthContext from '../../store/auth-context';
import manualIcon from "../../Img/manualsIcon.png"
import manual from "../../manual/Manual_AllergoEye_Placeholder.pdf"


const CardRight = (props) => {
    const ctx = useContext(AuthContext)
    const data = ctx.text.AE;
    const scrollY = props.scrollY

    let fading = scrollY >= 100 ? classes.animation : classes.text;


    return (
        <section className={classes.wrapperVid}>
            <div className={`${fading} ${classes.textWrap} ${classes.reSizeText}`}>
                <p>
                    {data.RightP}
                </p>
                <div className={classes.manualWrapper}>
                    <a className={classes.menuItem} href={manual}>
                        <img src={manualIcon} alt="Manual" />
                        Manual</a>
                </div>
            </div>
            <div className={classes.vidContainer2}>
                <video width="100%"
                    controls
                    playsInline
                >
                    <source src={vid} type="video/mp4" />
                </video>
            </div>
        </section>
    )
}

export default CardRight;