import { Fragment, useContext } from "react"
import AuthContext from "../../store/auth-context"
import graphs from "../../Img/slide2.png"
import summary from "../../Img/AllergoEye-talk.png"
import classes from "./ScGraphs.module.css"
import poster from "../../Img/EAACI_2023_Yarin_Flash_talks.png"

const ScGraphs = () => {
    const ctx = useContext(AuthContext)


    return (
        <Fragment>
            <section className={classes.wrap}>
                <div className={classes.container}>
                    <div>
                        <img src={graphs} alt="Graphs" />
                    </div>
                    <div>
                        <img src={summary} alt="Graphs" />
                    </div>
                </div>
                <div className={classes.pRight}>
                    <p>{ctx.text.ScWork.prague}</p>
                    <p>
                        {ctx.text.ScWork.footer}
                    </p>
                </div>
            </section>
            <section className={classes.poster}>
                <p className={classes.pRight}>
                    {ctx.text.ScWork.p6}
                </p>
                <div><img src={poster} alt="Poster" /></div>
            </section>
        </Fragment>
    )
}

export default ScGraphs