import { useContext } from "react";
import Header from "../../components/Header/Header"
import classes from "./subPages.module.css"
import AuthContext from "../../store/auth-context";
import Footer from "../../components/Footer/Footer";


const TermsandConditions = () => {

    const ctx = useContext(AuthContext)

    const data = ctx.text.TnC;

    return (
        <div id="page-wrap">
            <Header />
            <main className={classes.wrap}>
                <div>
                    <h1>{data.h1}</h1>
                    <div>
                        <h2>{data.n1}</h2>
                        <p>{data.p1}</p>
                        <p>{data.p2}</p>
                    </div>
                    <div>
                        <h2>{data.n2}</h2>
                        <p>{data.p3}</p>
                    </div>
                    <div>
                        <h2>{data.n3}</h2>
                        <p>{data.p4}</p>
                        <p>{data.p5}</p>
                        <p>{data.p6}</p>
                        <p>{data.p7}</p>
                        <p>{data.p8}</p>
                        <p>{data.p9}</p>
                        <p>{data.p10}</p>
                        <p>{data.p11}</p>
                        <p>{data.p12}</p>
                        <p>{data.p13}</p>
                        <p>{data.p14}</p>
                    </div>
                    <div>
                        <h2>{data.n4}</h2>
                        <p>{data.n4p}</p>
                    </div>
                    <div>
                        <h2>{data.n5}</h2>
                        <p>{data.np5}</p>
                    </div>
                    <div>
                        <h2>{data.n6}</h2>
                        <p>{data.np6}</p>
                    </div>
                    <div>
                        <h2>{data.n7}</h2>
                        <p>{data.np7}</p>
                    </div>
                    <div>
                        <h2>{data.n8}</h2>
                        <p>{data.np8}</p>
                    </div>
                    <div>
                        <h2>{data.n9}</h2>
                        <p>{data.np9}</p>
                        <p>{data.np91}</p>
                    </div>
                    <div>
                        <h2>{data.n10}</h2>
                        <p>{data.np10}</p>
                    </div>
                    <div>
                        <h2>{data.n11}</h2>
                        <p>{data.np111}</p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default TermsandConditions;