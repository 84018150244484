
import { useContext, useEffect, useRef  } from 'react';
import classes from "./AeMain.module.css";
import vid from "../../video/masknew0001-0500.webm"
import AuthContext from '../../store/auth-context';
import Arrow from "../UI/Arrow/Arrow"

const AeMain = (props) => {
    const ctx = useContext(AuthContext)
    const data = ctx.text.AE;
    const scrollY = props.scrollY
    let fading = scrollY >= 0 ? classes.animation : classes.text;

    const videoRef = useRef();

    useEffect(() => {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));
        const videoPlay = async () => {
            await delay(3000);
            videoRef.current.play();
        }
        videoPlay();

    }, [])

    return (
        <section className={classes.wrapper} >
                <h1>AllergoEye</h1>
                <div className={classes.wrap}>
                    <div className={classes.vidContainer}>
                        {<video ref={videoRef} width="100%"
                            muted
                            playsInline
                            loop
                            typeof='mp4' >
                            <source src={vid} type="video/webm" />
                        </video>}
                        {/* <img src={vid} alt="mask" /> */}
                    </div>
                    <div className={`${fading} ${classes.textWrap}`}>
                        <p>
                            {data.p1}
                        </p>
                        <div className={classes.textContainer}>
                            {/*need to do in table*/}
                            <div className={classes.borderP}>
                                <p >
                                    {data.divP1}
                                </p>
                                <p>
                                    {data.divP2}

                                </p>
                            </div>
                            <div className={classes.borderP}>
                                <p>
                                    {data.divP3}
                                </p>
                                <p>
                                    {data.divP4}
                                </p>
                            </div>
                        </div>
                        <p className={classes.mrgBtn}>
                            {data.divP5}
                        </p>
                    </div>
                </div>
                <Arrow />
        </section>
    )
}

export default AeMain;