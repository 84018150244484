import { ParallaxProvider } from 'react-scroll-parallax';
import MenuHamburger from './components/Header/Menu';
import "@fontsource/montserrat";
import Mainpage from './pages/MainPage';
import { Route, Routes } from 'react-router-dom';
import ScientificWork from './pages/ScientificWork';
import AE from './pages/AE';
import BusinessDevelopment from './pages/BusinessDevelopment';
// import Login from './pages/Login';
import Contact from './pages/Contact';
import DataProtection from './pages/SubPages/DataProtection';
import Impressum from './pages/SubPages/Impressum';
import TermsandConditions from './pages/SubPages/TermsandConditions';
import { useContext, useEffect } from 'react';
import AuthContext from './store/auth-context';
import User from './pages/User';
import Cookies from './components/Cookies/Cookies';

function App() {
  const ctx = useContext(AuthContext);

  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView({
        behavior: 'smooth'
    }, 500)

}, []);

  return (
    <ParallaxProvider>
    {!ctx.accepted && 
    <Cookies cookies={ctx.cookieHandler} accepted={ctx.acceptedHandler}/> }
      {ctx.accepted && <MenuHamburger />}
      <Routes>
        <Route path='/' element={<Mainpage />} />
        {ctx.user && <Route path='/Profile' element={<User />} />}
        <Route path='/AE' element={<AE />} />
        <Route path='/BusinessDevelopment' element={<BusinessDevelopment />} />
        <Route path='/scientific-work' element={<ScientificWork />} />
        {/* <Route path='/Login' element={<Login />} /> */}
        <Route path='/Contact' element={<Contact />} />
        <Route path='/DataProtection' element={<DataProtection />} />
        <Route path='/Imprint' element={<Impressum />} />
        <Route path='/TermsandConditions' element={<TermsandConditions />} />
        <Route path='*' element={<Mainpage />} />
      </Routes>
    </ParallaxProvider>
  );
}

export default App;