import Header from "../components/Header/Header";
import IntroPage from "../components/TextIntroPages/IntroPage";
import Footer from "../components/Footer/Footer";
import ScIndex from "../components/ScientificWork/ScIndex";
import ScGraphs from "../components/ScientificWork/ScGraphs";


const ScientificWork = () => {
    return (
        <div id="page-wrap" >
            <Header />
            <IntroPage />
            <ScIndex />
            <ScGraphs />
            <Footer />
        </div>
    )
}

export default ScientificWork;