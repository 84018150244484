import { useContext } from "react";
import AuthContext from "../../store/auth-context";
import classes from "./Footer.module.css"
import { Link } from 'react-router-dom';


const Footer = () => {
    const ctx = useContext(AuthContext);

    const data = ctx.text;

    return (
        <div className={classes.footer}>
            <ul>
                <li><Link to="/Imprint" alt="Imprint">{data.Impressum.title}</Link></li>
                <li><Link to="/DataProtection" alt="Data protection">{data.DataProtection.title}</Link></li>
                <li><Link to="/TermsandConditions" alt="Terms and conditions">{data.TnC.title}</Link></li>
            </ul>
        </div>
    )
}

export default Footer;