import Button from "../UI/Button/Button"
import Modal from "../UI/Modal/Moda"
import classes from "./Cookies.module.css"

const Cookies = (props) => {

    const cookiesHandler = (e) => {
        e.preventDefault()
        props.cookies()
        props.accepted()
    }

    const refuseHandler = (e) => {
        e.preventDefault()
        props.accepted()
    }

    return (
        <Modal className={classes.modalDiv}>
            <div className={classes.wrap}>
                <div className={classes.cookiesText}>
                    <h1>We use cookies</h1>
                    <p>Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies</p>
                </div>
                <div className={classes.btnWrap}>
                    <Button className={classes.btn} onClick={cookiesHandler}>Accept</Button>
                    <Button className={classes.btn} onClick={refuseHandler}>Refuse</Button>
                </div>
            </div>
        </Modal>
    )
}

export default Cookies