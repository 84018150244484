import { useContext, useReducer, useRef, useState } from "react";
import Input from "../Input/Input";
import Button from "../Button/Button";
import classes from "./Form.module.css"
import ReCAPTCHA from "react-google-recaptcha";
import AuthContext from "../../../store/auth-context";

const emailReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.includes('@') };
    }
    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.includes('@') }
    }
    return { value: '', isValid: false };
}

const nameReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length > 2 };
    }
    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length > 2 };
    }
    return { value: '', isValid: false };
}

const textReducer = (state, action) => {
    if (action.type === 'USER_INPUT') {
        return { value: action.val, isValid: action.val.trim().length > 0 };
    }
    if (action.type === 'INPUT_BLUR') {
        return { value: state.value, isValid: state.value.trim().length > 0 };
    }
    return { value: '', isValid: false };
}

const Form = (props) => {
    const [isClick, setIsClick] = useState(false)
    const emailInput = useRef();
    const nameInput = useRef();
    const textInput = useRef();
    const recaptchaRef = useRef();
    const [errorText, setErrorText] = useState(false)
    const ctx = useContext(AuthContext)
    const menu = ctx.text.Form

    const [emailState, dispatchEmail] = useReducer(emailReducer, { value: '', isValid: null });
    const [nameState, dispatchName] = useReducer(nameReducer, { value: '', isValid: null });
    const [textState, dispatchText] = useReducer(textReducer, { value: '', isValid: null });


    const emailChangeHandler = (event) => {
        dispatchEmail({ type: 'USER_INPUT', val: event.target.value });
    };

    const nameChangeHandler = (event) => {
        dispatchName({ type: 'USER_INPUT', val: event.target.value });
    };

    const textChangeHandler = (event) => {
        dispatchText({ type: 'USER_INPUT', val: event.target.value });
    };


    const submitHandler = (e) => {
        e.preventDefault();

        const recaptchaValue = recaptchaRef.current.getValue();
        if (!emailState.isValid || !nameState.isValid || !textState.isValid) {
            if (nameState.value.length < 1)
                setErrorText("Text length must be higher than 2")
            else
                setErrorText("Please fill the form")
            setIsClick(false)
        } else if (!recaptchaValue) {
            setErrorText("Please click the Captcha")
            setIsClick(false)
        }
        else {
            const url = "/btnSubmit"
            fetch(url,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        email: emailState.value,
                        name: nameState.value,
                        text: textState.value,
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).catch(res => {
                    console.log(res)
                })
            alert(menu.msg)
            setIsClick(true)
            window.location.reload(false);

        }
    }

    return (
        <div className={`${classes.formWrap} ${props.Form}`}>
            <div className={classes.formChildWrap}>
                <h1 className={classes.h1}>{menu.title}</h1>
                {!isClick && <p className={classes.messages}> {errorText}</p>}
                <form onSubmit={submitHandler}>
                    <Input ref={emailInput} label={menu.email} id={'email'} isValid={emailState.isValid} type={'email'} value={emailState.value} onChange={emailChangeHandler} required />
                    <Input ref={nameInput} label={menu.name} id={'name'} isValid={emailState.isValid} type={'name'} value={nameState.value} onChange={nameChangeHandler} />
                    <div className={classes.textAreaW}>
                        <textarea className={classes.sizeInput} ref={textInput} label={'text'} id={'text'} type={'text'} value={textState.value} onChange={textChangeHandler} />
                    </div>
                    <div className={classes.control}>
                        <ReCAPTCHA
                            sitekey={"6Ldx7FwiAAAAAP6y-PjnC0aT-HFK62gF_LtAuF23"}
                            ref={recaptchaRef}

                        />
                    </div>
                    <div className={classes.actions}>
                        <Button type="submit" className={`${classes.btn} `}>
                            {menu.btn}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Form;