import poster from "../../Img/Poster1.png";
import classes from "./ScIndex.module.css"
import Arrow from "../UI/Arrow/Arrow";


const ScIndex = () => {

    return (
        <section className={classes.poster}>
            <div><img src={poster} alt="Poster" /></div>
            <Arrow className={classes.arrowP} />
        </section>
    )
}

export default ScIndex;