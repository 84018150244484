import { Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import module from './Header.module.css'
import es from "../../Img/flags/es.png"
import de from "../../Img/flags/de.png"
import en from "../../Img/flags/uk.png"
import fr from "../../Img/flags/fr.png"
import cn from "../../Img/flags/cn.png"

const Header = (props) => {

    const ctx = useContext(AuthContext)

    const langOnClick = (e) => {
        e.preventDefault();
        ctx.langHandler(e.target.id)
        list()
    }

    const list = () => {
        let lang = (
            [
                { "id": "DE", "pays": "DE", "img": de, "selected": false},
                { "id": "EN", "pays": "EN", "img": en, "selected": false },
                { "id": "ES", "pays": "ES", "img": es, "selected": false },
                { "id": "FR", "pays": "FR", "img": fr, "selected": false },
                { "id": "CN", "pays": "CN", "img": cn, "selected": false }
            ])
        if (ctx.languageID) {
            for (let i = 0; i < lang.length; i++) {
                if (ctx.languageID === lang[i].id)
                    lang[i].selected = true
                else
                    lang[i].selected = false
            }
            //lang[index].selected = true;

        }
        lang = lang.map((lang) =>
            <Link onClick={langOnClick} key={lang.id}>
                <div >
                    <figure>
                        <img id={lang.id} src={lang.img} alt="https://www.flaticon.com/free-icon/flags" />
                    </figure>
                    {lang.selected && <p id={lang.id} className={module.selected}>{lang.pays}</p>}
                    {!lang.selected && <p id={lang.id}>{lang.pays}</p>}
                </div>
            </Link>

        )
        return (lang)
    }

    return (
        <header id="home" className={`${module.header} ${props.className}`} >
            <div className={module.wrapper} >
                <div className={module.langMenu} >
                    {list()}
                </div>
                {ctx.isLoggedIn && <div className={module.user}>
                    <p>{ctx.user.name}</p>
                </div>}
            </div>
            <Fragment>
                {props.children}
            </Fragment>
        </header>
    )
}

export default Header;