import Header from "../components/Header/Header";
import FormIntro from "../components/IntroPage/FormIntro/FormIntro";
import Footer from "../components/Footer/Footer";
import Main from "../components/UI/Main/Main";

const Contact = () => {
    const style = {
        height:'auto'
    }
    
    return (
        <div id="page-wrap" >
            <Header />
            <Main>
                <FormIntro style={style}/>
                <Footer />
            </Main>
        </div>

    )
}

export default Contact;