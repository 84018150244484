import Header from "../components/Header/Header";
import AeMain from "../components/AE/AeMain";
import Main from "../components/UI/Main/Main";
import CardRight from "../components/AE/CardRight";
import Footer from "../components/Footer/Footer"
import { useWindowScrollPositions as userPos } from "../hooks/useWindowScrollPositions"

const AE = () => {
    const { scrollY } = userPos()

    return (
        <div id="page-wrap" >
            <Header />
            <Main>
                <AeMain scrollY={scrollY} />
                <CardRight scrollY={scrollY} />
            </Main>
            <Footer />

        </div>
    )
}

export default AE;